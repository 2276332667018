import React from "react";

export default function Disclaimer() {
  return (
    <div>
      <h3> DISCLAIMER</h3>
      <p>
        Semua data yang diunggah oleh Pengguna Layanan berupa NIK, Alamat
        Lengkap, Nomor Telepon, Foto KTP, Form C1 KPU sepenuhnya milik Pengguna
        Layanan, dan digunakan untuk kepentingan Pengguna.
        <br />
        <br />
        Penyedia Layanan tidak bertanggungjawab atas penggunaan data pribadi
        yang diunggah oleh Pengguna untuk keperluan apapun yang dilakukan oleh
        Pengguna Layanan.
        <br />
        <br />
        Penyedia Layanan bertanggungjawab terhadap keamanaan data pribadi yang
        diunggah oleh Pengguna sesuai dengan ketentuan yang berlaku.
        <br />
        <br />
        Pengguna Layanan bersedia untuk bertanggung jawab secara
        peribadi/lembaga dan penuh atas terjadinya suatu tindakan penyalahgunaan
        sebagian/sepenuhnya data pribadi yang diunggah, dan Pengguna Layanan
        bersedia untuk membebaskan Penyedia Layanan dari segala permasalahan
        hukum yang timbul akibat kesalahan atau kekeliruan penggunaan data
        pribadi oleh Pengguna.
        <br />
        <br />
        Pengguna Layanan setuju untuk membebaskan serta tidak membebani Penyedia
        Layanan beserta para pengelola selaku pihak yang menaunginya atas segala
        keluhan, protes, klaim, gugatan oleh masyarakat atau Calon Pemilih
        terhadap penggunaan data pribadi yang dilakukan oleh Pengguna Layanan.
      </p>
    </div>
  );
}
