import React from "react";
import TextField from "@mui/material/TextField";

export default function TextInput({
  error,
  style,
  label,
  disabled,
  name,
  type,
  value,
  onChange,
  readOnly,
  placeholder,
  multiline,
  rows,
  helperText,
  required,
}) {
  return (
    <div className="div-input">
      <div className="label-input" style={style}>
        {label}
      </div>
      <TextField
        required={required}
        helperText={helperText}
        error={error}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        autoComplete="chrome-off"
        inputProps={{
          autocomplete: "off",
          readOnly: readOnly,
        }}
        size="small"
        placeholder={placeholder}
        fullWidth
      />
    </div>
  );
}
