import React from "react";

export default function Support() {
  return (
    <div>
      <h3> SUPPORT </h3>
      <p>
        Jika terdapat kendala pada proses penggunaan layanan, anda bisa
        menghubungi kami melalui kontak di bawah ini
        <br />
        1. Chat WhatsApp : +62 838 1253 6295
        <br />
        2. Chat WhatsApp : +62 859 2123 0586
        <br />
        Atau melalui email ke digitalprospek@gmail.com
      </p>
    </div>
  );
}
